<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>獎勵應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>點數管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold">點數管理</h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <div class="flex-shrink-0">
            <b-button
              v-if="checkPermission([consts.POINT_LOGS_LIST_VIEW])"
              class="mb-2 mb-xl-0 mr-2"
              variant="link"
              :to="{
                name: 'PointLogList',
              }"
            >
              <i class="fa fa-folder-open"></i>點數異動紀錄
            </b-button>
            <b-button
              v-if="checkPermission([consts.POINT_LIST_CREATE])"
              class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
              variant="primary"
              :to="{ name: 'PointCreate' }"
            >
              <i class="fa fa-plus"></i>新增點數
            </b-button>
          </div>

          <b-form-select
            class="mr-3"
            v-model="query.merchant_id"
            :options="merchants"
            :disabled="merchants.length == 2"
            @change="handlePageReset"
          ></b-form-select>

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              placeholder="搜尋會員名稱、手機、外部編碼"
              v-model="query.keyword"
              v-on:keyup.enter="handleSearch()"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch()"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <b-table
        striped
        hover
        responsive
        :items="points"
        :fields="fields"
        :busy="isLoading"
        :no-local-sorting="true"
        @sort-changed="handleSort"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            class="mr-1"
            variant="inverse-primary"
            :to="{
              name: 'PointView',
              params: {
                point_id: data.item.id,
                merchant_id: query.merchant_id,
              },
            }"
          >
            <span class="mdi mdi-eye"></span> 查看
          </b-button>
          <b-button
            class="mr-1"
            variant="inverse-warning"
            :to="{
              name: 'PointEdit',
              params: {
                point_id: data.item.id,
                merchant_id: query.merchant_id,
              },
            }"
          >
            <span class="mdi mdi-lead-pencil"></span> 編輯
          </b-button>
          <b-button variant="inverse-danger" @click="deletePoint(data.item)">
            <span class="mdi mdi-delete"></span> 刪除
          </b-button>
        </template>
      </b-table>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="total"
        :perPage="query.per_page"
        :perPageOptions="perPageOption"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointApi from "../../../apis/point";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  data() {
    return {
      consts: consts,
      isLoading: false,
      points: [],
      merchants: [{ value: null, text: "請選擇" }],
      fields: [
        {
          key: "customer_name",
          label: "會員名稱",
        },
        {
          key: "point",
          label: "點數",
        },
        {
          key: "used_point",
          label: "已用點數",
        },
        {
          key: "created_at",
          label: "入點時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          },
        },
        {
          key: "start_at",
          label: "點數可用日",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          },
        },
        {
          key: "expired_at",
          label: "點數過期日",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return moment(value).format("YYYY-MM-DD HH:mm");
            }
          },
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      currentPage: 1,
      total: 0,
      initialized: false,
      perPageOption: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 50, value: 50 },
        { text: 100, value: 100 },
      ],
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        sort_by: "created_at",
        order_by: "desc",
        merchant_id: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  async created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.fetchMerchants();
    this.initialized = true;
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchPoints();
    },
  },
  methods: {
    async fetchPoints() {
      if (!this.query.merchant_id) return;
      try {
        this.isLoading = true;
        const { data } = await pointApi.getPoints(this.query.merchant_id, {
          ...this.query,
        });
        this.total = data.meta.total;
        this.points = data.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        const merchants = data.data;
        const defaultMerchant =
          merchants.find(
            (merchant) => merchant.type === this.defaultMerchantType
          ) ?? merchants.find((merchant) => merchant.type === "LINE");

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        await this.fetchPoints();
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async deletePoint(point) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await pointApi.removePoint(point.merchant_id, point);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 ",
              });
              this.fetchPoints()();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handleSearch() {
      if (!this.query.merchant_id) return;
      this.handlePageReset();
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>
